var didScroll;
var lastScrollTop = 0;
var delta = 10;

jQuery(window).scroll(function ($) {
  // console.log('The page is scrolling');
  var navbarHeight = jQuery('.wr-navigation-wrapper').outerHeight();

  var didScroll = true;

  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {

    var st = jQuery(this).scrollTop();

    if (jQuery('.wr-navigation-wrapper').hasClass('hero-home')) {

      if (Math.abs(lastScrollTop - st) <= delta) return;

      if (st > lastScrollTop && st > navbarHeight) {
        jQuery('.wr-navigation-wrapper').removeClass('clear');
      }
      lastScrollTop = st;

      if (st < 100) {
        jQuery('.wr-navigation-wrapper').addClass('clear');
      }
    }
  }

  if (jQuery(window).scrollTop() > 100) {
    if (!jQuery('.wr-navigation-wrapper').hasClass('shadow')) {
      jQuery('.wr-navigation-wrapper').addClass('shadow');
    }
  } else {
    if (jQuery('.wr-navigation-wrapper').hasClass('shadow')) {
      jQuery('.wr-navigation-wrapper').removeClass('shadow');
    }
  }
});