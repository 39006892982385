/*********************************
 * ACF GOOGLE MAP FUNCTION
 *********************************/
;(function ($) {
  /**
   * initMap
   *
   * Renders a Google Map onto the selected jQuery element
   *
   * @date    22/10/19
   * @since   5.8.6
   *
   * @param   jQuery $el The jQuery element.
   * @return  object The map instance.
   */
  function initMap($el) {
    // Find marker elements within map.
    var $markers = $el.find(".marker")
    // Create gerenic map.
    var mapArgs = {
      zoom: $el.data("zoom") || 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#fffefd",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#fffdf9",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bd5934",
            },
          ],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bd5934",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#3d8f7f",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#b6da9a",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#3d8f7f",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#c9c3c1",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#f0e6dc",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#fff",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#d8d8d8",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#d3c1be",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#d3c1be",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.icon",
          stylers: [
            {
              Hue: "#f1f2f2",
            },
            {
              saturation: -60,
            },
            {
              lightness: 5,
            },
          ],
        },

        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [
            {
              color: "#d8d8d8",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bf9863",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#75d1f0",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#fffdf9",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#fffdf9",
            },
          ],
        },
      ],
    }
    var map = new google.maps.Map($el[0], mapArgs)
    var infowindow = new google.maps.InfoWindow()

    // Add markers.
    map.markers = []
    $markers.each(function () {
      initMarker($(this), map, infowindow)
    })

    // Center map based on markers.
    centerMap(map)

    // Return map instance.
    return map
  }

  /**
   * initMarker
   *
   * Creates a marker for the given jQuery element and map.
   *
   * @date    22/10/19
   * @since   5.8.6
   *
   * @param   jQuery $el The jQuery element.
   * @param   object The map instance.
   * @return  object The marker instance.
   */
  function initMarker($marker, map, infowindow) {
    // Get position from marker.
    var infowindow = infowindow
    var lat = $marker.data("lat")
    var lng = $marker.data("lng")
    var id = $marker.data("map-id")
    var markerOutlineColor = $marker.data("outline-color")
    var markerColor = $marker.data("color")
    var markerCategoryString = $marker.data("marker-categories")
    var parkTitle = $marker.data("park-title")
    if (typeof markerCategoryString !== "undefined") {
      var markerCategoriesArray = markerCategoryString.split(",")
    } else {
      var markerCategoriesArray = []
    }
    var latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    }
    // Create marker instance.
    var svg =
      `<svg id="MapMarker" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="15px" height="25px" viewBox="0 0 10 20.71">
                <defs>
                  <style>
                    .marker-outline {
                      fill: ` +
      markerOutlineColor +
      `;
                    }
              
                    .marker-color {
                      fill: ` +
      markerColor +
      `;
                    }
                  </style>
                </defs>
                <g>
                  <path class="marker-outline" d="M11,5.64a5,5,0,0,0-10,0,5,5,0,0,0,3,4.58V19.4A2,2,0,0,0,8,19.4l0-9.17A5,5,0,0,0,11,5.64Z" transform="translate(-1 -0.64)"/>
                  <path class="marker-color" d="M5.42,9.8v9.6a.58.58,0,0,0,1.16,0V9.8a5.44,5.44,0,0,1-.58,0A5.67,5.67,0,0,1,5.42,9.8Z" transform="translate(-1 -0.64)"/>
                  <circle class="marker-color" cx="5" cy="5" r="3.62"/>
                </g>
              </svg>`
    var marker = new google.maps.Marker({
      position: latLng,
      map: map,
      id: id,
      title: parkTitle,
      icon: "data:image/svg+xml;charset=UTF-8;base64," + btoa(svg),
      categories: markerCategoriesArray,
    })
    // Append to reference for later use.
    map.markers.push(marker)
    // If marker contains HTML, add it to an infoWindow.

    // Show info window when marker is clicked.
    google.maps.event.addListener(marker, "click", function () {
      let markerID = this.id
      if ($marker.html() && parkTitle !== "") {
        $(`#${markerID}`).find(".infowindow-wrapper").removeClass("hidden")
        $(`#${markerID}`).find(".infowindow-wrapper").addClass("shown")
        $(`#${markerID}`).find(".infowindow-wrapper").html($marker.html())
      }
      $(`#${markerID}`)
        .find(".close-infowindow")
        .on("click", function () {
          $(`#${markerID}`).find(".infowindow-wrapper").removeClass("shown")
          $(`#${markerID}`).find(".infowindow-wrapper").addClass("hidden")
        })
    })
  }

  /**
   * centerMap
   *
   * Centers the map showing all markers in view.
   *
   * @date    22/10/19
   * @since   5.8.6
   *
   * @param   object The map instance.
   * @return  void
   */
  function centerMap(map) {
    // Create map boundaries from all map markers.
    var bounds = new google.maps.LatLngBounds()
    map.markers.forEach(function (marker) {
      bounds.extend({
        lat: marker.position.lat(),
        lng: marker.position.lng(),
      })
    })
    // Case: Single marker.
    if (map.markers.length == 1) {
      map.setCenter(bounds.getCenter())
      // Case: Multiple markers.
    } else {
      map.fitBounds(bounds)
    }
  }

  filterMarkers = function (map, selectedValue) {
    for (i = 0; i < map.markers.length; i++) {
      marker = map.markers[i]
      // If is same category or category not picked
      if (marker.categories.includes(selectedValue)) {
        marker.setVisible(true)
      } else if (selectedValue == "") {
        marker.setVisible(true)
      } else {
        marker.setVisible(false)
      }
    }
  }

  // Render maps on page load.
  $(document).ready(function () {
    var map
    $(".acf-map").each(function () {
      map = initMap($(this))
    })
    $(".map-filter-select").on("change", function (e) {
      var selectedValue = $(this).val()
      filterMarkers(map, selectedValue)
    })
  })
})(jQuery)
/************************************
 * END ACF GOOGLE MAP FUNCTION
 *************************************/
