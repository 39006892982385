$(function() {

    if($('.reaches-map-wrapper').length > 0) {

        let reachesBlock = $('.reaches-map-wrapper');

        let reach = reachesBlock.find('.reaches .reach-with-parks');

        let reachMap = reachesBlock.find('.reaches-image-wrapper svg');

        let reachMapBoundaries = reachMap.find('.reach-boundary');

        reachMapBoundaries.each(function() {
            let  data = $(this).attr('data-reach-name');
            $(this).on('mouseenter', function() {
                $(this).addClass('active');
                $('.reaches .reach-with-parks.' + data).addClass('active');
            });
            $(this).on('mouseleave', function() {
                $(this).removeClass('active');
                $('.reaches .reach-with-parks.' + data).removeClass('active');
            });
        });
        
        reach.each(function() {
            let data = $(this).attr('data-reach-slug');
            $(this).on('mouseenter', function() {
                reachMap.find('.' + data).addClass('active');
            });
            $(this).on('mouseleave', function() {
                reachMap.find('.' + data).removeClass('active');
            });
        });

    }

});
