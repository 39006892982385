$( document ).ready(function() {

    scrollRiver();

    $('#update-type, #river-reach, #park').on('change', function() {

        scrollToAnchor('updates-map-block');

        updateUpdates();

    });

});  

$(window).scroll(function() {

    scrollRiver();

});

$(window).resize(function() {

    scrollRiver();

});

function scrollToAnchor(aid){
    var aTag = $("section[name='"+ aid +"']");
    $('html,body').animate({scrollTop: aTag.offset().top - 180},'fast');
}

function updateUpdates() {
    var $type = $("select#update-type option:selected").val();
    var $reach = $("select#river-reach option:selected").val();
    var $park = $("select#park option:selected").val();
    $('.update').addClass('hidden');
    if($type!='' && $reach!='' && $park!='') {
        $('.update.type-' + $type + '.reach-' + $reach + '.park-' + $park).removeClass('hidden');
    } else if($type!='' && $reach!='') {
        $('.update.type-' + $type + '.reach-' + $reach).removeClass('hidden');
    } else if($type!='' && $park!='') {
        $('.update.type-' + $type + '.park-' + $park).removeClass('hidden');
    } else if($reach!='' && $park!='') {
        $('.update.reach-' + $reach + '.park-' + $park).removeClass('hidden');
    } else if($type!='') {
        $('.update.type-' + $type).removeClass('hidden');
    } else if($reach!='') {
        $('.update.reach-' + $reach).removeClass('hidden');
    } else if($park!='') {
        $('.update.park-' + $park).removeClass('hidden');
    } else {
        $('.update').removeClass('hidden');
    }
    scrollRiver();
    
}

function scrollRiver() {
    let winWidth = $(window).width();
    if(winWidth == undefined) winWidth = 0;
    let block = $('.updates-map-wrapper'); 
    let river = $('.updates-map-left');
    let riverMobile = $('.river-mobile');
    let updates = $('.updates-map-right');
    let update = $('.update');
    let riverHeight = river.height();
    let updatesHeight = updates.height();
    let offset = block.offset()?.top;
    let scroll = $(window).scrollTop();
    let height = block.height();
    let winHeight = $(window).height();
    let top = 280;
    let adjust = 200;
    let adjust2 = 80;
    let scrollAdjust = 180;
    if(offset == undefined) offset = 0;
    if(scroll == undefined) scroll = 0;
    if(height == undefined) height = 0;
    if(winHeight == undefined) winHeight = 0;
    if(riverHeight == undefined) riverHeight = 0;

    if(winWidth < 768) {
        top = 40;
        scrollAdjust = -392;
        adjust2 = 250;
        adjust = 0;
    } else if(winWidth < 1200) { 
        top = 130;
        scrollAdjust = 130;
        adjust2 = 415;
        adjust = 0;
    }

    if((offset < (scroll + scrollAdjust) && scroll < (offset + height - winHeight + adjust)) && (updatesHeight > winHeight)) {        
        river.addClass('sticky');
        river.css('top', top + 'px');
        riverMobile.addClass('sticky');
        riverMobile.css('top', top + 'px');
    } else {
        river.removeClass('sticky');
        riverMobile.removeClass('sticky');
        if((scroll >= (updatesHeight + offset - winHeight) && (updatesHeight > winHeight))) {
            let top = updatesHeight - winHeight + riverHeight + adjust2;
            river.css('top', top + 'px');
            riverMobile.css('top', top + 'px');
        } else {
            river.css('top', '0');
            riverMobile.css('top', '435px');
        }
    }
    update.each(function() {
        let offset = $(this).offset()?.top;
        let scroll = $(window).scrollTop();
        let scrollAdjust = 400;
        if(winWidth < 768) {
            scrollAdjust = 400;
        } else if(winWidth < 1200) { 
            scrollAdjust = 750;
        }
        if(offset == undefined) offset = 0;
        if(scroll == undefined) scroll = 0;
        if($(this).data('reach') != undefined) {
            let reach = $(this).data('reach').split(',');
            if(offset - scroll - scrollAdjust < 0) {
                if(!$(this).hasClass('hidden')) {
                    if(reach[0]=='') {
                        $('.river-path').addClass('active');
                        $('.reach-group').removeClass('active');
                    } else {
                        $('.river-path').removeClass('active');
                        $('.reach-group').removeClass('active');
                        for(let i = 0; i < reach.length; i++) {
                            $('.' + reach[i]).addClass('active');
                        }
                    }
                }
            }
        }
    });

}