/*******************
 * Script Imports
 *******************/
import "popper.js"
import "bootstrap"
import "slick-slider"
import "./../node_modules/bootstrap-table/dist/bootstrap-table.js"
import "./blocks/image-slider/image-slider"
import "./blocks/tabs/tabs"
import "./blocks/updates-map/updates-map"

import "./vendor/vendor"
import "./utility/detect-ie"
import "./utility/is-touch-device"
import "./utility/acf-google-map"
import "./theme/scripts"
import "./theme/on-load.js"
import "./theme/on-scroll.js"
import "./theme/on-resize.js"

// REACHES BLOCK (GSAP)
import "./blocks/reaches/reaches.js"
// REACHES MAP BLOCK
import "./blocks/reaches-map/reaches-map.js"
/*******************
 * Style Imports
 *******************/
import "../scss/main.scss"
