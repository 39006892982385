$(function () {

  console.log("Theme scripts working");

  $("body").addClass("FOUC");

  // WHITE RIVER MENU

  let reachMenuWrapper = $('.wr-navigation-take-over .wr-reaches-menu-wrapper');
  let reachOverflowIcon = $('.wr-reaches-menu-wrapper .wr-reaches-overflow-icon');
  let reachMenuWrapperHeight = reachMenuWrapper.get(0).scrollHeight;

  $('.wr-navigation .wr-hamburger-icon').on('click', function (e) {
    $('.wr-navigation-take-over').toggleClass('active');
    //OVERFLOW FOR MENU
    setTimeout(function () {
      if ($('.wr-navigation-take-over').hasClass('active')) {
        if (reachMenuWrapperHeight > $(window).outerHeight()) {
          reachMenuWrapper.addClass('overflow');
        } else {
          reachMenuWrapper.removeClass('overflow');
        }
      }
    }, 500);
  });

  $('.wr-navigation-take-over .wr-close-menu').on('click', function (e) {
    $('.wr-navigation-take-over').toggleClass('active');
    if (reachMenuWrapper.hasClass('overflow')) {
      reachMenuWrapper.removeClass('overflow');
    }
  });

  $('.wr-navigation .wr-search-icon').on('click', function (e) {
    if (!$('.wr-search-box-wrapper').hasClass('active')) {
      $('.wr-search-box-wrapper').addClass('active');
    };
  })
  $('.wr-search-box-wrapper .wr-search-close').on('click', function (e) {
    if ($('.wr-search-box-wrapper').hasClass('active')) {
      $('.wr-search-box-wrapper').removeClass('active');
    };
  });

  //////////////////////////


  // WR NAVIGATION ACCORDION FUNCTIONALITY
  // var timeout;

  // if ($(".wr-menu li.menu-item-has-children").hasClass('current_page_parent')) {
  //   $(".wr-menu li.current_page_parent").find('a').addClass('active').next().slideDown();
  // }
  // $(".wr-menu li.menu-item-has-children").on('mouseenter', function (e) {
  //   if(!$(this).hasClass('active')) {

  //     if (timeout != null) { 
  //       clearTimeout(timeout); 
  //     }

  //     timeout = setTimeout(() => {
  //       $(".wr-menu li.menu-item-has-children").removeClass("active");
  //       $(".wr-menu > ul ul").slideUp();
  //       if (!$(this).find('a').next().is(":visible")) {
  //         $(this).find('a').next().slideDown();
  //         $(this).addClass("active");
  //       }
  //     }, 250);

  //   }
  // }).on('mouseleave', function() {
  //   if($(this).hasClass('active')) {
  //     if (timeout != null) { 
  //         // $(".wr-menu li.menu-item-has-children").removeClass("active");
  //         // $(".wr-menu > ul ul").slideUp();
  //       clearTimeout(timeout); 
  //     }
  //   timeout = null;
  //   }
  // });

  $(".wr-menu li.menu-item-has-children > a").on('click', function (e) {
    e.preventDefault();
    if ($(this).hasClass('active')) {
      window.location = $(this).attr('href');
      return;
    }
    $(".wr-menu li.menu-item-has-children > a").removeClass("active");
    $(".wr-menu > ul ul").slideUp();
    if (!$(this).next().is(":visible")) {
      $(this).next().slideDown();
      $(this).addClass("active");
    }
  });
  //////////////////////////


  // ARCHIVE ONCHANGE EVENT
  let archiveFilterValue = $('.archive-category-filter').val();
  $('.archive-category-filter').on('change', function () {
    if (archiveFilterValue == $(this).val()) {
      return;
    } else {
      window.location.href = '/category/' + $(this).val();
    }
  });

  let archiveMonthValue = $('.archive-month-filter').val();
  $('.archive-month-filter option').each(function () {
    if ($(this).val() == window.location.href) {
      $(this).attr('selected', 'selected')
    }
  })
  $('.archive-month-filter').on('change', function () {
    if (archiveMonthValue == $(this).val()) {
      return;
    } else {
      window.location.href = $(this).val();
    }
  });
  //////////////////////////


  //SINGLE POST COMMENTS BUTTON FUNCTIONALITY HIDING FORM SECTION
  //comment section
  let colorPanelSection = $('.color-panel-wrapper.single');
  $('.comment .comment-reply-link').on('click', function () {
    colorPanelSection.addClass('hide');
  });
  $('.comment-respond #cancel-comment-reply-link').on('click', function () {
    colorPanelSection.removeClass('hide');
  });

  //FORMIDABLE RADIO CHECKBOX
  $(".with_frm_style .frm_radio input[type=radio]").after("<span>"); // Remove this line if you do not wish to style radio buttons
  $(".with_frm_style .frm_checkbox input[type=checkbox]").after("<span>"); // Remove this line if you do not wish to style checkboxes

});