$(window).on('load', function() {

    if($('.reaches-wrapper').length > 0) {

        gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);
    
        MorphSVGPlugin.convertToPath('.reach-animation circle');

        if($(window).outerWidth() > 767) {
    
            var tl = gsap.timeline({paused: true, defaults: {duration: 1}}),
                river = $(".reach-animation #river"),
                circle1 = $(".reach-animation #reach-circle-1"),
                circle2 = $(".reach-animation #reach-circle-2"),
                circle3 = $(".reach-animation #reach-circle-3"),
                circle4 = $(".reach-animation #reach-circle-4"),
                circle5 = $(".reach-animation #reach-circle-5"),
                circle6 = $(".reach-animation #reach-circle-6");
        
            tl.to(river, {morphSVG:"#river-anchor", duration:1,
            scrollTrigger:{
                trigger:".reach-animation",
                // markers:true,
                scrub:true,
                start:"top 20%",
                end:`${$('.reach-animation').outerHeight()}px`
                }
            }, 0)
            .to(circle1, {morphSVG:"#reach-circle-anchor-1", duration:1, 
                scrollTrigger:{
                trigger:".reach-animation",
                // markers:true,
                scrub:true,
                start:"top 20%",
                end:`${$('.reach-animation').outerHeight()}px`
                }}, "<")
            .to(circle2, {morphSVG:"#reach-circle-anchor-2", duration:1, 
                scrollTrigger:{
                trigger:".reach-animation",
                // markers:true,
                scrub:true,
                start:"top 20%",
                end:`${$('.reach-animation').outerHeight()}px`
                }
            }, "<")
            .to(circle3, {morphSVG:"#reach-circle-anchor-3", duration:1,
                scrollTrigger:{
                trigger:".reach-animation",
                // markers:true,
                scrub:true,
                start:"top 20%",
                end:`${$('.reach-animation').outerHeight()}px`
                }         
            }, "<")
            .to(circle4, {morphSVG:"#reach-circle-anchor-4", duration:1, 
                scrollTrigger:{
                trigger:".reach-animation",
                // markers:true,
                scrub:true,
                start:"top 20%",
                end:`${$('.reach-animation').outerHeight()}px`
                }
            }, "<")
            .to(circle5, {morphSVG:"#reach-circle-anchor-5", duration:1, 
                scrollTrigger:{
                trigger:".reach-animation",
                // markers:true,
                scrub:true,
                start:"top 20%",
                end:`${$('.reach-animation').outerHeight()}px`
                }
            }, "<")
            .to(circle6, {morphSVG:"#reach-circle-anchor-6", duration:1, 
            scrollTrigger:{
                trigger:".reach-animation",
                // markers:true,
                scrub:true,
                start:"top 20%",
                end:`${$('.reach-animation').outerHeight()}px`
                }
            }, "<");
        
            const ST = ScrollTrigger.create ({
            // animation:tl,
            trigger: '.reach-animation',
            //animation: TL1,
            // markers: true,
            start: 'top 20%',
            end: 'top 100px',
            endTrigger:'#south-bluffs',
            pin:$('.reach-animation'),
            
            });
        
            $('.reach-section').each(function(i) {
                ScrollTrigger.create({
                    trigger:$(this),
                    // markers:true,
                    start:'top center',
                    end:'bottom center',
                    onEnter: () => {
                    var circleSectionId = $(this).attr('id');
                    var svgCircle = $('.reach-circle[data-section="' + circleSectionId + '"]');
                    // console.log(svgCircle);
                    if(!svgCircle[0].classList.contains('active')) {
                        svgCircle[0].classList.add('active');
                    } else {
                        svgCircle[0].classList.remove('active');
                    }
                    },
                    onLeave: () => {
                    var circleSectionId = $(this).attr('id');
                    var svgCircle = $('.reach-circle[data-section="' + circleSectionId + '"]');
                    if(!svgCircle[0].classList.contains('active')) {
                        svgCircle[0].classList.add('active');
                    } else {
                        svgCircle[0].classList.remove('active');
                    }
                    },
                    onEnterBack: () => {
                    var circleSectionId = $(this).attr('id');
                    var svgCircle = $('.reach-circle[data-section="' + circleSectionId + '"]');
                    if(!svgCircle[0].classList.contains('active')) {
                        svgCircle[0].classList.add('active');
                    } else {
                        svgCircle[0].classList.remove('active');
                    }
                    },
                    onLeaveBack: () => {
                    var circleSectionId = $(this).attr('id');
                    var svgCircle = $('.reach-circle[data-section="' + circleSectionId + '"]');
                    if(!svgCircle[0].classList.contains('active')) {
                        svgCircle[0].classList.add('active');
                    } else {
                        svgCircle[0].classList.remove('active');
                    }
                    },
                });
            });

            // $(window).on('load', function() {
            //     ST.refresh();
            // });

            $(window).on('resize', function() {
                ST.refresh();
            });
        }

            // REACTES OVERFLOW FUNCTIONALITY
    
        function setOverflow() {
    
            if($(window).outerWidth() < 768) {
    
                let reachSection = $('.reaches-wrapper .reach-section');
    
                reachSection.each(function(i) {
    
                    let contentSection = $(this).find('.reach-content');
    
                    contentSection.each(function() {
    
                        $(this).css({'max-height': 'unset'});
    
                        $(this).find('.reach-parks .parks-item').each(function() {
    
                            $(this).unbind('click');
    
                            if(contentSection.hasClass('overflow')) {
                                contentSection.removeClass('overflow');
                            }
                        });
                    });
                });
    
            } else {
    
                let reachSection = $('.reaches-wrapper .reach-section');
    
                reachSection.each(function(i) {
    
                    let contentSection = $(this).find('.reach-section-content');
        
                    contentSection.each(function() {
    
                        let height = $(this).height();
    
                        $(this).find('.reach-content').css({'max-height': height});
            
                        $(this).find('.reach-parks .parks-item').each(function() {

                            let contentAccordion = $(this).closest('.reach-parks');
                            let contentP = contentAccordion.prev();
                            let combinedHeight = contentP.outerHeight(true) + contentAccordion.outerHeight(true);

                            if(combinedHeight > height) {
                                contentSection.find('.reach-content').addClass('overflow');
                            } else {
                                contentSection.find('.reach-content').removeClass('overflow');
                            } 

    
                            $(this).on('click', function() {
    
                                let _this = $(this);
    
                                setTimeout(function() {
    
                                    let contentAccordion = _this.closest('.reach-parks');
                                    let contentP = contentAccordion.prev();
                                    let combinedHeight = contentP.outerHeight(true) + contentAccordion.outerHeight(true);
    
                                    if(combinedHeight > height) {
                                        contentSection.find('.reach-content').addClass('overflow');
                                    } else {
                                        contentSection.find('.reach-content').removeClass('overflow');
                                    }
    
                                }, 500);
                            });
                        })
                    });
                });
            }
        }
    
        setOverflow();
    
        $(window).on('resize', function() {
            // REACTES OVERFLOW FUNCTIONALITY
            setOverflow();
        });
    
        // ANCHOR LINK FUNCTIONALITY
        var root = $('html, body');
        $('.reaches-wrapper .reach-circle').on('click', function () {
        var section_id = $(this).attr('data-section');
            root.animate({
                scrollTop: $('#'+section_id).offset().top - 150
            }, 0, 'linear');
            setTimeout(function() {
                window.location.hash = '#' + section_id;
            },100)

            return false;

        });

        var windowHash = window.location.hash;
        if(windowHash) {
            $('html, body').animate({
                scrollTop: $(windowHash).offset().top - 150
            }, 100, 'linear');
        }

    }
    
});