jQuery(window).resize(function($) {
 console.log('The window has been resized.');

    let reachMenuWrapper = jQuery('.wr-navigation-take-over .wr-reaches-menu-wrapper');
    let reachMenuWrapperHeight = reachMenuWrapper.get(0).scrollHeight;

    if(jQuery('.wr-navigation-take-over').hasClass('active') ){
        if(reachMenuWrapperHeight  > jQuery(window).outerHeight()) {
            reachMenuWrapper.addClass('overflow');
        } else {
            reachMenuWrapper.removeClass('overflow');
        }
    }


});