$(function () {
  $(".image-slider-block").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  //Footer Slider
  if ($('.footer-slider').length > 0) {
    $('.footer-slider').each(function () {
      var footerSliderArgs = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        rows: 0,
        arrows: true,
        accessibility: true,
        variableWidth: true,
        prevArrow: '.footer-slider-prev',
        nextArrow: '.footer-slider-next',
        responsive: [{
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            variableWidth: false,
          }
        }, ],
      }
      $(this).slick(footerSliderArgs);
    });
  }
});